import React from 'react';
import Collapsible from 'react-collapsible';
import config from '../../config';

import css from './FAQ.module.css';

const FAQ = props => {
  const maybeShowGST = config.custom.featureToggles[config.env].chargeGst
    ? 'not displayed'
    : 'hiring';
  const cancellationPolicy = (
    <React.Fragment>
      <p>
        You can cancel a booking or booking request at any time. Refunds are dependent on when you
        cancel:
      </p>
      <ul className={css.faqAnswerDotPoints}>
        <li>
          If the trailer owner hasn't confirmed your booking request, the payment will be cancelled
          immediately. It may continue to show on your banking app as "pending" until your bank
          processes the cancellation, which usually happens within a few minutes or hours.
        </li>
        <li>
          If the booking is confirmed, and is cancelled more than 24 hours before the scheduled
          pick-up time, a full refund will be processed.
        </li>
        <li>
          If the booking is confirmed, and is cancelled less than 24 hours before the scheduled
          pick-up time, no refund will be provided.
        </li>
      </ul>
    </React.Fragment>
  );
  const FAQs = [
    {
      categories: ['listing', 'business'],
      question: 'How much does it cost?',
      answer: (
        <p>
          It costs nothing for an owner to list a trailer for hire on Local Trailer Hire. People
          looking to rent a trailer pay a small service fee on top of the hire price.
        </p>
      ),
    },
    {
      categories: ['listing'],
      question: 'How much does the average trailer earn?',
      answer: (
        <React.Fragment>
          <p>Last year:</p>
          <ul className={css.faqAnswerDotPoints}>
            <li>
              The average return for a single trailer on Local Trailer Hire was $2,654 for the year.
            </li>
            <li>
              The best performing single trailer on Local Trailer Hire returned a whopping $12,290
              for the year; it was a car trailer in NSW.
            </li>
          </ul>
        </React.Fragment>
      ),
    },
    {
      categories: ['listing'],
      question: 'Can I control when the trailer can be booked?',
      answer: (
        <div>
          <p>
            You can set pick-up and return hours when creating your listing (and update them any
            time). You can have multiple pick-up and return windows per day, and different settings
            for each day of the week. If you're only available for pick-up and return before and
            after work hours, this is no issue. Customers will only be able to book pick-up and
            return during the hours you set.
          </p>
          <p>
            You can also set "unavailable times" whenever your trailer is going to be unavailable
            (e.g. when you want to use it yourself) and customers won’t be able to place any
            bookings that overlap these periods.
          </p>
          <p>You can find these settings under "My listings" then "Manage availability".</p>
        </div>
      ),
    },
    {
      categories: ['listing'],
      question: 'Can I list multiple trailers?',
      answer: (
        <p>
          Yes, you can list as many trailers as you like as long as they are all available for hire.
        </p>
      ),
    },
    {
      categories: ['listing', 'business'],
      question: 'How and when do I get paid?',
      answer: (
        <p>
          We transfer the hire fee to you via bank transfer 24 hours after the start of the hire. It
          usually arrives in your bank account within 3-5 days.
        </p>
      ),
    },
    {
      categories: ['listing'],
      question: 'Does my trailer need to be registered?',
      answer: (
        <p>
          Yes. In most states, trailer registration is mandatory. In Victoria, small trailers may be
          unregistered but when they are used for business purposes, like being hired out, they do
          need to be registered. Unregistered trailers are not able to be listed on Local Trailer
          Hire.
        </p>
      ),
    },
    {
      categories: ['listing'],
      question: 'What happens if something gets broken?',
      answer: (
        <p>
          As per the rental terms, customers take full responsibility for the trailer while it is in
          their possession. Local Trailer Hire provides a $2,000 guarantee. A damage excess of up to
          $1,000 is payable by the customer. Full details can be found in the{' '}
          <a href="/rental-terms">rental contract</a>.
        </p>
      ),
    },
    {
      categories: ['listing'],
      question: 'Can my trailer be listed under a business name?',
      answer: (
        <p>
          Yes. You'll need to enter a personal name when signing up to Local Trailer Hire. You can
          add your business name to your user profile once you have signed up. You will need to
          enter your business details when setting up payouts.
        </p>
      ),
    },
    {
      categories: ['listing'],
      question: 'Can you help me find a good deal on a new trailer?',
      answer: (
        <p>
          We have a partnership with a trailer wholesaler, and can offer exclusive pricing for
          trailers to be listed on Local Trailer Hire – please send an email to{' '}
          <a href="mailto:hello@localtrailerhire.com.au">hello@localtrailerhire.com.au</a> and we
          can explore further.
        </p>
      ),
    },
    {
      categories: [maybeShowGST],
      question: 'GST is not noted on my receipt - how much GST has been paid on my rental?',
      answer: (
        <p>
          At this point in time, no GST is payable on trailer rental through Local Trailer Hire.
        </p>
      ),
    },
    {
      categories: ['hiring'],
      question:
        "I've made a booking and can't find where to pick up the trailer. Where can I find it?",
      answer: (
        <p>
          Simply login to localtrailerhire.com.au, go to <a href="/inbox/orders">My bookings</a>{' '}
          (also available via the burger menu in the top left if you're logged in on a mobile), and
          then select your booking. If the booking has been confirmed by the trailer owner, you will
          find the trailer's pick-up address under the picture of the trailer, near the top of the
          page. If you are still waiting on confirmation from the owner, you can only see the
          approximate location of the trailer, shown on the map on the trailer listing page.
        </p>
      ),
    },
    {
      categories: ['hiring'],
      question:
        "I've booked a trailer and I need to make contact with the trailer owner. How can I contact them?",
      answer: (
        <p>
          Simply login to localtrailerhire.com.au, go to <a href="/inbox/orders">My bookings</a>{' '}
          (also available via the burger menu in the top left if you're logged in on a mobile), and
          then select your booking. Here you will find the opportunity to message the owner at the
          bottom of the page.
        </p>
      ),
    },
    {
      categories: ['hiring'],
      question:
        "Local Trailer Hire advises that when I request a booking I have not been charged. However, a charge appears on my credit card. What's going on?",
      answer: (
        <p>
          When you request a booking, we put a temporary hold charge on your card to ensure the
          funds are available. This should show up in your online banking as a "pending"
          transaction. If the booking is confirmed, the payment will be completed, and if not, the
          "pending" transaction will expire and disappear from your online banking.
        </p>
      ),
    },
    {
      categories: ['hiring'],
      question:
        'What happens if I have an accident whilst using a trailer booked through Local Trailer Hire?',
      answer: (
        <p>
          As per the terms and conditions, customers take full responsibility for the trailer while
          it is in their possession. Local Trailer Hire provides a $2,000 guarantee. A damage excess
          of up to $1,000 is payable by the customer. Full details can be found in the{' '}
          <a href="/rental-terms">rental contract</a>.
        </p>
      ),
    },
    {
      categories: ['hiring'],
      question:
        'I’ve gone to pick up the trailer and I don’t believe it is in a roadworthy condition. What should I do?',
      answer: (
        <p>
          This would be a most unusual situation, however, you should not complete the hire. Please
          send an email to{' '}
          <a href="mailto:hello@localtrailerhire.com.au">hello@localtrailerhire.com.au</a> and we’ll
          sort it out. Sorry for the inconvenience…
        </p>
      ),
    },
    {
      categories: ['hiring'],
      question: 'Why is the owner of the trailer asking to see my drivers licence?',
      answer: (
        <p>
          As part of our identity verification process, we match the details you provided upon
          registration with your drivers licence details. The trailer owner is just making sure that
          you are you!
        </p>
      ),
    },
    {
      categories: ['hiring'],
      question: 'How can I extend my hire?',
      answer: (
        <React.Fragment>
          <p>If you wish to extend your hire, please submit another booking request.</p>
          <p>
            If you have booked for less than 24 hours (or any multiple of 24 hours) and you want to
            extend it to the full 24 hour period, you can contact the trailer owner and ask if this
            is possible and they may be able to extend the booking for you.
          </p>
        </React.Fragment>
      ),
    },
    {
      categories: ['hiring'],
      question: 'What is the cancellation policy on Local Trailer Hire?',
      answer: cancellationPolicy,
    },
    {
      categories: ['hiring'],
      question: 'How do I cancel my booking?',
      answer: (
        <React.Fragment>
          <p>
            Easy - login to localtrailerhire.com.au, go to <a href="/inbox/orders">My bookings</a>{' '}
            (also available via the burger menu in the top left if you're logged in on a mobile),
            and then select your booking. Press the "Cancel booking" or "Cancel booking request"
            button on the booking page.
          </p>
          {cancellationPolicy}
        </React.Fragment>
      ),
    },
    {
      categories: ['hiring'],
      question: 'Are my personal details safe with Local Trailer Hire?',
      answer: (
        <p>
          They sure are! Please take a look at our <a href="/privacy-policy">privacy policy</a> for
          further details.
        </p>
      ),
    },

    {
      categories: ['managingBookings'],
      question: 'What if a customer wants to cancel their booking?',
      answer: (
        <p>
          Bookings that have been confirmed can be cancelled by the customer for a full refund until
          24 hours before the start of the hire. After that time, the customer can still press the
          "Cancel booking" button, making your trailer available for other customers, but they will
          not usually receive a refund – in this case, the hire fee will be transferred to you
          automatically 24 hours after the cancellation.
        </p>
      ),
    },
    {
      categories: ['managingBookings'],
      question: 'How can bookings be extended?',
      answer: (
        <React.Fragment>
          <p>
            The customer can submit another booking request through the website, selecting the
            pick-up date and time immediately following on from their original booking.
          </p>
          <p>
            Alternatively, at your discretion, you may allow the customer to extend a booking
            originally made for less than 24 hours (or any multiple of 24 hours) up to the full
            period the customer could have booked for the same price (e.g. to the full 24 hours).
            This will not charge the customer an extra fee. You can do this using the "Change
            booking times" button on the booking page.
          </p>
        </React.Fragment>
      ),
    },
    {
      categories: ['managingBookings'],
      question: 'What do I do if a customer wants to change the date and time of a booking?',
      answer: (
        <React.Fragment>
          <p>
            You can reschedule an existing booking using the "Change booking times" button on the
            booking page. This allows you to select new pick-up and drop-off dates and times.
          </p>
          <ul className={css.faqAnswerDotPoints}>
            <li>
              When changing the booking times, you will not be able to select dates and times that
              would make the booking longer than the period the customer has paid for.
            </li>
            <li>Changing booking times to shorten a booking will not trigger a partial refund.</li>
          </ul>
          <p>
            If a hire needs to be extended to a duration longer than the customer has paid for, they
            will need to submit another booking request, selecting the pick-up date and time
            immediately following on from their original booking.
          </p>
        </React.Fragment>
      ),
    },
    {
      categories: ['managingAccount'],
      question: 'How do I update my personal information, password or bank payout details?',
      answer: (
        <p>
          Simply login to localtrailerhire.com.au click either the burger menu on mobile (top left)
          or your initials/photo on web (top right) and select “Account.” Here you can update your
          email address, mobile phone number, residential address, your drivers licence details,
          your password and your bank details.
        </p>
      ),
    },
    {
      categories: ['hiring'],
      question: 'How far in advance can I book a trailer?',
      answer: <p>Bookings for trailers can be made up to 88 days in advance.</p>,
    },
    {
      categories: ['managingBookings'],
      question: 'How do I close my listing?',
      answer: (
        <React.Fragment>
          <p>
            If you just want to mark your trailer unavailable for a short period of time, preventing
            bookings for this period, we recommend using the unavailable times feature. You can find
            this by navigating to "My listings" then "Manage availability". At the top of the page
            you'll find the option to add unavailable times.
          </p>
          <p>
            If you really want to close your listing so that it is not bookable at all and doesn't
            show in search results, navigate to the "My listings" page. Select the three dots in the
            top right of the trailer listing image and select "Unlist trailer". You can come back to
            the same page to re-open your listing later, should you wish.
          </p>
        </React.Fragment>
      ),
    },
  ];

  const selectedFAQs = FAQs.filter(FAQ => FAQ.categories.indexOf(props.category) !== -1);

  return (
    <div className={css.root}>
      {selectedFAQs.map((FAQ, index) => {
        return (
          <Collapsible key={index} open={false} trigger={<h3>{FAQ.question}</h3>}>
            {FAQ.answer}
          </Collapsible>
        );
      })}
    </div>
  );
};

export default FAQ;
