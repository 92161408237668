import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ReactPlayer from 'react-player/vimeo';
import config from '../../config';

import css from './SectionForOwnersFeatures.module.css';

const MEDIUM = 768;
const LARGE = 1024;

const playerHeight = viewportWidth => {
  if (viewportWidth >= LARGE) {
    return '100%';
  }
  if (viewportWidth >= MEDIUM) {
    return '250px';
  }
  return '250px';
};

const fee = config.custom.featureToggles[config.env].chargeGst ? '19.8% fee (inc GST)' : '16% fee';

const SectionForOwnersFeatures = props => {
  const { rootClassName, className, viewport } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <h2 className={css.subheading}>A great side income</h2>
      <div className={css.content}>
        <div className={css.videoContainer}>
          <ReactPlayer
            url="https://vimeo.com/634324286"
            controls
            width="100%"
            height={playerHeight(viewport.width)}
          />
        </div>
        <div className={css.rightContainer}>
          <div className={css.featureHeadingAndWords}>
            <h3 className={css.featureHeading}>Attractive returns</h3>
            <div>
              Last year, the average trailer on localtrailerhire.com.au returned $2,344. Some
              trailers are making as much as $6,500 per year.
            </div>
          </div>
          <div className={css.featureHeadingAndWords}>
            <h3 className={css.featureHeading}>No fees for owners</h3>
            <div>
              It is completely free to list and hire out your trailer using Local Trailer Hire. We
              add a {fee} to the cost of the hire, which is paid by the customer.
            </div>
          </div>
          <div className={css.featureHeadingAndWords}>
            <h3 className={css.featureHeading}>Automated payments</h3>
            <div id="trailer-guarantee">
              Hire fees are transferred directly to your bank account the day after pick-up, and
              will arrive in your bank account within a few days.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

SectionForOwnersFeatures.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionForOwnersFeatures.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionForOwnersFeatures;
