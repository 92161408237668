import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './SectionForOwnersBenefits.module.css';

import priceImage from './images/price.svg';
import workImage from './images/work.svg';
import guaranteeImage from './images/guarantee.svg';
import transferImage from './images/transfer.svg';
import { NamedLink } from '../../components';
import config from '../../config';

const SectionForOwnersBenefits = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);

  const fee = config.custom.featureToggles[config.env].chargeGst
    ? '19.8% fee (inc GST)'
    : '16% fee';

  return (
    <div className={classes}>
      <h2 className={css.subheading}>Why use localtrailerhire.com.au?</h2>
      <div className={css.benefitsContainer}>
        <div className={css.benefitFirst}>
          <img src={guaranteeImage} role="presentation" className={css.icon} />
          <div className={css.benefitHeadingAndWords}>
            <h3 className={css.benefitHeading}>Peace of mind</h3>
            <div>
              You can hire out your trailer with confidence thanks to our $2,000
              <NamedLink
                name="ForOwnersPage"
                to={{ hash: 'trailer-guarantee' }}
                className={css.link}
              >
                trailer guarantee
              </NamedLink>
              ! Every hire is subject to our rental terms – no paper forms required.
            </div>
          </div>
        </div>
        <div className={css.benefit}>
          <img src={transferImage} role="presentation" className={css.icon} />
          <div className={css.benefitHeadingAndWords}>
            <h3 className={css.benefitHeading}>Automated bookings and payment</h3>
            <div>
              No need to muck around wih back-and-forth messaging or cash. The whole process is
              automated and can be done from your phone.
            </div>
          </div>
        </div>
        <div className={css.benefit}>
          <img src={priceImage} role="presentation" className={css.icon} />
          <div className={css.benefitHeadingAndWords}>
            <h3 className={css.benefitHeading}>It's free to list and hire out your trailer</h3>
            <div>
              Owners get paid the price at which they list their trailer. We add a {fee} to the cost
              of the hire, which is paid by the customer.
            </div>
          </div>
        </div>
        <div className={css.benefitLast}>
          <img src={workImage} role="presentation" className={css.icon} />
          <div className={css.benefitHeadingAndWords}>
            <h3 className={css.benefitHeading}>You are in control</h3>
            <div>You set the price and availability. Change it whenever you want!</div>
          </div>
        </div>
      </div>
    </div>
  );
};

SectionForOwnersBenefits.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionForOwnersBenefits.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionForOwnersBenefits;
