import React from 'react';
import { string } from 'prop-types';
import { IconReviewStar } from '..';
import classNames from 'classnames';
import { propTypes } from '../../util/types';

import css from './OwnerStats.module.css';

const OwnerStats = props => {
  const { rootClassName, className, ownerUser } = props;

  const classes = classNames(rootClassName || css.root, className);

  const numHires = ownerUser.attributes.profile.publicData?.bookingStatsAllTime?.numHires || 0;
  const numberOfReviews = ownerUser.attributes.profile.publicData?.reviewStats?.numReviews || 0;
  const averageRating =
    ownerUser.attributes.profile.publicData?.reviewStats?.avgReviewRating.toFixed(2) || 0;
  const responseDelay =
    ownerUser.attributes.profile.publicData?.responseStatsLast12Months
      ?.meanBookingResponseDelayDisplay || '';

  return numHires > 1 ? (
    <div className={classes}>
      <div className={css.ownerStats}>
        {numberOfReviews > 0 ? (
          <React.Fragment>
            <span>
              <IconReviewStar rootClassName={css.star} />
              {averageRating}
            </span>
            <span className={css.linkSeparator}>•</span>
          </React.Fragment>
        ) : null}
        <span>{numberOfReviews} reviews</span>
        <span className={css.linkSeparator}>•</span>
        <span>{numHires} hires</span>
        <div className={css.ownerStatLong}>
          {/* <p>{(acceptanceRate * 100).toFixed(0)}% confirmed</p> */}
          {responseDelay ? <p>Usually responds within {responseDelay}</p> : null}
        </div>
      </div>
    </div>
  ) : null;
};

OwnerStats.defaultProps = {
  rootClassName: null,
  className: null,
};

OwnerStats.propTypes = {
  rootClassName: string,
  className: string,
  ownerUser: propTypes.user,
};

export default OwnerStats;
