import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './SectionForHirersFeatures.module.css';

import windowImage from './images/window.svg';
import conversationImage from './images/conversation.svg';
import locationImage from './images/location.svg';
import paymentImage from './images/payment.svg';
import timeImage from './images/time.svg';
import stopImage from './images/stop-sign.svg';

const SectionForHirersFeatures = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <h2>Hiring a trailer</h2>
      <div className={css.intro}>
        <p className={css.intro}>
          Until now, if you needed a trailer, you'd have been stuck with traditional hiring models
          that are time consuming, inconvenient and expensive. localtrailerhire.com.au is different.
          We help you to hire a trailer from someone local quickly, conveniently and at a great
          price! What are you waiting for? Make a booking request today!
        </p>
      </div>
      <div className={css.featuresContainer}>
        <div className={css.feature}>
          <img src={locationImage} alt="search by location" className={css.icon} />
          <div className={css.featureHeadingAndWords}>
            <h3 className={css.featureHeading}>1. Search</h3>
            <div>Enter your postcode or suburb into the search bar to find a local trailer.</div>
          </div>
        </div>
        <div className={css.feature}>
          <img src={windowImage} alt="enter booking details" className={css.icon} />
          <div className={css.featureHeadingAndWords}>
            <h3 className={css.featureHeading}>2. Enter dates and times</h3>
            <div>
              Use the booking calendar to note when you would like to pick up and return the
              trailer. Click "Next".
            </div>
          </div>
        </div>
        <div className={css.feature}>
          <img src={paymentImage} alt="secure payment by credit card" className={css.icon} />
          <div className={css.featureHeadingAndWords}>
            <h3 className={css.featureHeading}>3. Enter payment details</h3>
            <div>
              Enter your credit card details. Click “Request to book”. Your card will be
              pre-authorised but won’t be charged until your booking is confirmed.
            </div>
          </div>
        </div>
        <div className={css.feature}>
          <img src={timeImage} alt="stopwatch" className={css.icon} />
          <div className={css.featureHeadingAndWords}>
            <h3 className={css.featureHeading}>4. Sit tight!</h3>
            <div>
              The trailer owner will be advised of your request and just needs to confirm that the
              trailer is available at the requested time. Owners usually respond within an hour!
              You’ll receive an email when your booking is confirmed along with the address where
              the trailer is located.
            </div>
          </div>
        </div>
        <div className={css.feature}>
          <img src={stopImage} alt="trailer time" className={css.icon} />
          <div className={css.featureHeadingAndWords}>
            <h3 className={css.featureHeading}>5. Trailer time!</h3>
            <div>
              Simply arrive at the address provided at the nominated time and your trailer will be
              waiting! Knock on the door and the owner will give you a hand to get it on your car
              and run you through how to use it safely. Don’t forget your drivers licence!
            </div>
          </div>
        </div>
        <div className={css.feature}>
          <img src={conversationImage} alt="winning" className={css.icon} />
          <div className={css.featureHeadingAndWords}>
            <h3 className={css.featureHeading}>6. All good things must come to an end!</h3>
            <div>
              At the end of your hire, just return the trailer to the same location at the time you
              nominated on your booking. If you are unable to return the trailer at the scheduled
              time, let the owner know ASAP by messaging them through the booking details page.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

SectionForHirersFeatures.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionForHirersFeatures.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionForHirersFeatures;
