import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { NamedLink } from '../../components';

import css from './SectionHelpGettingStarted.module.css';

// import convenientImage from './images/convenient-location.png';
// import greatPriceImage from './images/great-price-wallet.png';
// import quickImage from './images/quick-booking.png';

const SectionHelpGettingStarted = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <>
      <h2 className={css.helpTitle}>Getting started</h2>
      <div className={classes}>
        <NamedLink name="ForHirersPage" className={css.link}>
          {/* <img src={guaranteeImage} role="presentation" className={css.icon} /> */}
          <h3 className={css.heading}>How to book a trailer hire</h3>
        </NamedLink>
        <NamedLink name="ForOwnersPage" className={css.link}>
          {/* <img src={guaranteeImage} role="presentation" className={css.icon} /> */}
          <h3 className={css.heading}>How to earn some cash with your trailer</h3>
        </NamedLink>

        <NamedLink name="ExplainCancellationPage" className={css.link}>
          {/* <img src={guaranteeImage} role="presentation" className={css.icon} /> */}
          <h3 className={css.heading}>Cancellations and refunds</h3>
        </NamedLink>
        <NamedLink name="ExplainPaymentPage" className={css.link}>
          {/* <img src={guaranteeImage} role="presentation" className={css.icon} /> */}
          <h3 className={css.heading}>
            The payment process when hiring through Local Trailer Hire
          </h3>
        </NamedLink>
      </div>
    </>
  );
};

SectionHelpGettingStarted.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionHelpGettingStarted.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionHelpGettingStarted;
