import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import * as validators from '../../util/validators';
import {
  Form,
  PrimaryButton,
  FieldCheckbox,
  FieldTextInput,
  LocationAutocompleteInputField,
  FieldPhoneNumberInput,
  FieldSelect,
  FieldBirthdayInput,
} from '../../components';
import config from '../../config';

import css from './ConfirmSignupForm.module.css';

const KEY_CODE_ENTER = 13;

const ConfirmSignupFormComponent = props => {
  const [showReferrerFieldState, setShowReferrerFieldState] = useState(
    props.referrerName === 'crystal'
  );
  const [showPromoTermsState, setShowPromoTermsState] = useState(
    props.promoCode?.toUpperCase() === 'FUEL'
  );

  const howDidYouHearAboutUsOptions = config.custom.howDidYouHearAboutUsOptions;

  const unorderedHowDidYouHearAboutUsOptions = howDidYouHearAboutUsOptions
    .map(value => ({ value, sort: Math.random() }))
    .sort((a, b) => a.sort - b.sort)
    .map(({ value }) => value)
    .concat({ key: 'other', label: 'Other' });

  return (
    <FinalForm
      {...props}
      render={formRenderProps => {
        const {
          rootClassName,
          className,
          formId,
          handleSubmit,
          inProgress,
          invalid,
          intl,
          onOpenTermsOfService,
          onOpenFuelPromoTerms,
          promoCode,
          referrerName,
          authInfo,
          idp,
          values,
        } = formRenderProps;

        // email
        const emailLabel = intl.formatMessage({
          id: 'ConfirmSignupForm.emailLabel',
        });
        const emailPlaceholder = intl.formatMessage({
          id: 'ConfirmSignupForm.emailPlaceholder',
        });
        const emailRequiredMessage = intl.formatMessage({
          id: 'ConfirmSignupForm.emailRequired',
        });
        const emailRequired = validators.required(emailRequiredMessage);
        const emailInvalidMessage = intl.formatMessage({
          id: 'ConfirmSignupForm.emailInvalid',
        });
        const emailValid = validators.emailFormatValid(emailInvalidMessage);

        // firstName
        const firstNameLabel = intl.formatMessage({
          id: 'ConfirmSignupForm.firstNameLabel',
        });
        const firstNamePlaceholder = intl.formatMessage({
          id: 'ConfirmSignupForm.firstNamePlaceholder',
        });
        const firstNameRequiredMessage = intl.formatMessage({
          id: 'ConfirmSignupForm.firstNameRequired',
        });
        const firstNameRequired = validators.required(firstNameRequiredMessage);

        // lastName
        const lastNameLabel = intl.formatMessage({
          id: 'ConfirmSignupForm.lastNameLabel',
        });
        const lastNamePlaceholder = intl.formatMessage({
          id: 'ConfirmSignupForm.lastNamePlaceholder',
        });
        const lastNameRequiredMessage = intl.formatMessage({
          id: 'ConfirmSignupForm.lastNameRequired',
        });
        const lastNameRequired = validators.required(lastNameRequiredMessage);

        // contact phone number
        const phoneNumberLabel = intl.formatMessage({
          id: 'SignupForm.phoneNumberLabel',
        });
        const phoneNumberPlaceholder = intl.formatMessage({
          id: 'SignupForm.phoneNumberPlaceholder',
        });
        const phoneNumberRequiredMessage = intl.formatMessage({
          id: 'SignupForm.phoneNumberRequired',
        });
        const phoneNumberMinLengthMessage = intl.formatMessage({
          id: 'SignupForm.phoneNumberMinLength',
        });
        const phoneNumberPrefixMessage = intl.formatMessage({
          id: 'SignupForm.phoneNumberPrefix',
        });
        const phoneNumberRequired = validators.required(phoneNumberRequiredMessage);
        const phoneNumberMinLength = validators.minLength(phoneNumberMinLengthMessage, 10);
        const phoneNumberPrefix = validators.phoneNumberPrefix(phoneNumberPrefixMessage);

        // driversLicenceNumber
        const driversLicenceNumberLabel = intl.formatMessage({
          id: 'SignupForm.driversLicenceNumberLabel',
        });
        const driversLicenceNumberPlaceholder = intl.formatMessage({
          id: 'SignupForm.driversLicenceNumberPlaceholder',
        });
        const driversLicenceNumberRequiredMessage = intl.formatMessage({
          id: 'SignupForm.driversLicenceNumberRequired',
        });
        const driversLicenceNumberRequired = validators.required(
          driversLicenceNumberRequiredMessage
        );
        const driversLicenceNumberMinLengthMessage = intl.formatMessage({
          id: 'SignupForm.driversLicenceNumberMinLength',
        });
        const driversLicenceNumberMinLength = validators.minLength(
          driversLicenceNumberMinLengthMessage,
          6
        );

        // driversLicenceExpiryDate
        const driversLicenceExpiryDateLabel = intl.formatMessage({
          id: 'SignupForm.driversLicenceExpiryDateLabel',
        });
        const driversLicenceExpiryDatePlaceholder = intl.formatMessage({
          id: 'SignupForm.driversLicenceExpiryDatePlaceholder',
        });
        const driversLicenceExpiryDateRequiredMessage = intl.formatMessage({
          id: 'SignupForm.driversLicenceExpiryDateRequired',
        });
        const driversLicenceExpiryDateRequired = validators.required(
          driversLicenceExpiryDateRequiredMessage
        );

        // driversLicenceIssuedBy
        const driversLicenceIssuedByLabel = intl.formatMessage({
          id: 'SignupForm.driversLicenceIssuedByLabel',
        });
        const driversLicenceIssuedByPlaceholder = intl.formatMessage({
          id: 'SignupForm.driversLicenceIssuedByPlaceholder',
        });
        const driversLicenceIssuedByRequiredMessage = intl.formatMessage({
          id: 'SignupForm.driversLicenceIssuedByRequired',
        });
        const driversLicenceIssuedByRequired = validators.required(
          driversLicenceIssuedByRequiredMessage
        );

        const states = config.custom.states;

        // howDidYouHearAboutUs
        const howDidYouHearAboutUsLabel = intl.formatMessage({
          id: 'SignupForm.howDidYouHearAboutUsLabel',
        });
        const howDidYouHearAboutUsPlaceholder = intl.formatMessage({
          id: 'SignupForm.howDidYouHearAboutUsPlaceholder',
        });
        const howDidYouHearAboutUsRequiredMessage = intl.formatMessage({
          id: 'SignupForm.howDidYouHearAboutUsRequired',
        });
        const howDidYouHearAboutUsRequired = validators.required(
          howDidYouHearAboutUsRequiredMessage
        );

        // residentialAddress
        const residentialAddressLabel = intl.formatMessage({
          id: 'SignupForm.residentialAddressLabel',
        });
        const residentialAddressPlaceholder = intl.formatMessage({
          id: 'SignupForm.residentialAddressPlaceholder',
        });
        const residentialAddressRequiredMessage = intl.formatMessage({
          id: 'SignupForm.residentialAddressRequired',
        });
        const residentialAddressNotRecognizedMessage = intl.formatMessage({
          id: 'SignupForm.residentialAddressNotRecognized',
        });

        const optionalText = intl.formatMessage({
          id: 'SignupForm.optionalText',
        });
        const buildingMessage = intl.formatMessage(
          { id: 'SignupForm.building' },
          { optionalText: optionalText }
        );
        const buildingPlaceholderMessage = intl.formatMessage({
          id: 'SignupForm.buildingPlaceholder',
        });

        // referrerName
        const referrerNameLabel = intl.formatMessage(
          { id: 'SignupForm.referrerNameLabel' },
          { optionalText: optionalText }
        );
        const referrerNamePlaceholder = intl.formatMessage({
          id: 'SignupForm.referrerNamePlaceholder',
        });

        // promoCode
        const promoCodeLabel = intl.formatMessage(
          { id: 'SignupForm.promoCodeLabel' },
          { optionalText: optionalText }
        );

        const handleTermsKeyUp = e => {
          // Allow click action with keyboard like with normal links
          if (e.keyCode === KEY_CODE_ENTER) {
            onOpenTermsOfService();
          }
        };

        const handleHowDidYouHearAboutUsChange = newValue => {
          setShowReferrerFieldState(newValue === 'referred');
        };

        const handlePromoCodeChange = e => {
          setShowPromoTermsState(e.target.value.toUpperCase() === 'FUEL');
        };

        const termsLink = (
          <span
            className={css.termsLink}
            onClick={onOpenTermsOfService}
            role="button"
            tabIndex="0"
            onKeyUp={handleTermsKeyUp}
          >
            <FormattedMessage id="ConfirmSignupForm.termsAndConditionsLinkText" />
          </span>
        );

        // termsAccept
        const termsAcceptLabel = (
          <span className={css.termsText}>
            <FormattedMessage
              id="ConfirmSignupForm.termsAndConditionsAcceptText"
              values={{ termsLink }}
            />
          </span>
        );

        // TODO this message is not currently used because FieldCheckbox doesn't deal with validations
        const termsAcceptRequiredMessage = intl.formatMessage({
          id: 'SignupForm.termsAcceptRequired',
        });
        const termsAcceptRequired = validators.requiredCheckbox(termsAcceptRequiredMessage);

        const fuelPromoTermsLink = (
          <span className={css.termsLink} onClick={onOpenFuelPromoTerms} role="button" tabIndex="0">
            <FormattedMessage id="SignupForm.fuelPromoReadTermsText" />
          </span>
        );

        // If authInfo is not available we should not show the ConfirmForm
        if (!authInfo) {
          return;
        }

        // Initial values from idp provider
        const { email, firstName, lastName } = authInfo;

        const classes = classNames(rootClassName || css.root, className);
        const submitInProgress = inProgress;
        const submitDisabled = invalid || submitInProgress;

        const types = ['address'];

        return (
          <Form className={classes} onSubmit={handleSubmit}>
            <div>
              <FieldTextInput
                type="email"
                id={formId ? `${formId}.email` : 'email'}
                name="email"
                autoComplete="email"
                label={emailLabel}
                placeholder={emailPlaceholder}
                initialValue={email}
                validate={validators.composeValidators(emailRequired, emailValid)}
              />
              <div className={css.name}>
                <FieldTextInput
                  className={css.firstNameRoot}
                  type="text"
                  id={formId ? `${formId}.firstName` : 'firstName'}
                  name="firstName"
                  autoComplete="given-name"
                  label={firstNameLabel}
                  placeholder={firstNamePlaceholder}
                  initialValue={firstName}
                  validate={firstNameRequired}
                />
                <FieldTextInput
                  className={css.lastNameRoot}
                  type="text"
                  id={formId ? `${formId}.lastName` : 'lastName'}
                  name="lastName"
                  autoComplete="family-name"
                  label={lastNameLabel}
                  placeholder={lastNamePlaceholder}
                  initialValue={lastName}
                  validate={lastNameRequired}
                />
              </div>
              <FieldPhoneNumberInput
                className={css.phoneNumber}
                type="text"
                id={formId ? `${formId}.phoneNumber` : 'phoneNumber'}
                name="phoneNumber"
                label={phoneNumberLabel}
                placeholder={phoneNumberPlaceholder}
                validate={validators.composeValidators(
                  phoneNumberRequired,
                  phoneNumberMinLength,
                  phoneNumberPrefix
                )}
              />
              <FieldTextInput
                className={css.driversLicenceNumber}
                type="text"
                id={formId ? `${formId}.driversLicenceNumber` : 'driversLicenceNumber'}
                name="driversLicenceNumber"
                label={driversLicenceNumberLabel}
                placeholder={driversLicenceNumberPlaceholder}
                validate={validators.composeValidators(
                  driversLicenceNumberRequired,
                  driversLicenceNumberMinLength
                )}
              />
              <label
                htmlFor={formId ? `${formId}.driversLicenceExpiryDate` : 'driversLicenceExpiryDate'}
                className={css.driversLicenceExpiryDateLabel}
              >
                {driversLicenceExpiryDateLabel}
              </label>
              <FieldBirthdayInput
                id={formId ? `${formId}.driversLicenceExpiryDate` : 'driversLicenceExpiryDate'}
                name="driversLicenceExpiryDate"
                className={css.driversLicenceExpiryDate}
                label={'Day'}
                labelForMonth={'Month'}
                labelForYear={'Year'}
                format={v => v}
                valueFromForm={values.driversLicenceExpiryDate}
                years={'future'}
                validate={validators.composeValidators(driversLicenceExpiryDateRequired)}
              />
              <FieldSelect
                className={css.driversLicenceIssuedBy}
                id={formId ? `${formId}.driversLicenceIssuedBy` : 'driversLicenceIssuedBy'}
                name="driversLicenceIssuedBy"
                label={driversLicenceIssuedByLabel}
                validate={driversLicenceIssuedByRequired}
              >
                <option disabled value="">
                  {driversLicenceIssuedByPlaceholder}
                </option>
                {states.map(c => (
                  <option key={c.key} value={c.key}>
                    {c.label}
                  </option>
                ))}
              </FieldSelect>
              <div className={css.residentialAddress}>
                <LocationAutocompleteInputField
                  className={css.locationAddress}
                  inputClassNames={[css.locationAutocompleteInput]}
                  iconClassName={css.locationAutocompleteInputIcon}
                  predictionsClassName={css.predictionsRoot}
                  validClassName={css.validLocation}
                  name="residentialAddress"
                  label={residentialAddressLabel}
                  placeholder={residentialAddressPlaceholder}
                  useDefaultPredictions={false}
                  validate={validators.composeValidators(
                    validators.autocompleteSearchRequired(residentialAddressRequiredMessage),
                    validators.autocompletePlaceSelected(residentialAddressNotRecognizedMessage)
                  )}
                  id={formId ? `${formId}.residentialAddress` : 'residentialAddress'}
                  types={types}
                />
              </div>

              <FieldTextInput
                className={css.building}
                type="text"
                name="building"
                id="building"
                label={buildingMessage}
                placeholder={buildingPlaceholderMessage}
              />

              {!referrerName ? (
                <FieldSelect
                  className={css.howDidYouHearAboutUs}
                  id={formId ? `${formId}.howDidYouHearAboutUs` : 'howDidYouHearAboutUs'}
                  name="howDidYouHearAboutUs"
                  label={howDidYouHearAboutUsLabel}
                  validate={howDidYouHearAboutUsRequired}
                  onChange={handleHowDidYouHearAboutUsChange}
                >
                  <option disabled value="">
                    {howDidYouHearAboutUsPlaceholder}
                  </option>
                  {unorderedHowDidYouHearAboutUsOptions.map(c => (
                    <option key={c.key} value={c.key}>
                      {c.label}
                    </option>
                  ))}
                </FieldSelect>
              ) : (
                <FieldTextInput
                  type="hidden"
                  id={formId ? `${formId}.howDidYouHearAboutUs` : 'howDidYouHearAboutUs'}
                  name="howDidYouHearAboutUs"
                  defaultValue="referred"
                />
              )}

              {showReferrerFieldState ? (
                <FieldTextInput
                  className={css.referrerName}
                  type={referrerName ? 'hidden' : 'text'}
                  id={formId ? `${formId}.referrerName` : 'referrerName'}
                  name="referrerName"
                  autoComplete="new-password"
                  label={referrerName ? null : referrerNameLabel}
                  defaultValue={referrerName ? referrerName : null}
                  placeholder={referrerNamePlaceholder}
                />
              ) : null}

              <FieldTextInput
                className={css.promoCode}
                type="text"
                id={formId ? `${formId}.promoCode` : 'promoCode'}
                name="promoCode"
                label={promoCodeLabel}
                defaultValue={promoCode}
                onBlur={handlePromoCodeChange}
              />

              {showPromoTermsState ? (
                <div className={css.promoTerms}>{fuelPromoTermsLink}</div>
              ) : null}

              <div className={css.termsAccept}>
                <FieldCheckbox
                  id={formId ? `${formId}.termsAccept` : 'termsAccept'}
                  name="termsAccept"
                  label={termsAcceptLabel}
                  validate={termsAcceptRequired}
                  value="termsAccept"
                />
              </div>
            </div>

            <div className={css.bottomWrapper}>
              <p className={css.bottomWrapperText}></p>
              <PrimaryButton type="submit" inProgress={submitInProgress} disabled={submitDisabled}>
                <FormattedMessage id="ConfirmSignupForm.signUp" values={{ idp: idp }} />
              </PrimaryButton>
            </div>
          </Form>
        );
      }}
    />
  );
};

ConfirmSignupFormComponent.defaultProps = { inProgress: false };

const { bool, func } = PropTypes;

ConfirmSignupFormComponent.propTypes = {
  inProgress: bool,

  onOpenTermsOfService: func.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const ConfirmSignupForm = compose(injectIntl)(ConfirmSignupFormComponent);
ConfirmSignupForm.displayName = 'ConfirmSignupForm';

export default ConfirmSignupForm;
