import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import isEqual from 'lodash/isEqual';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import * as validators from '../../util/validators';
import { ensureCurrentUser } from '../../util/data';
import {
  isChangeEmailTakenError,
  isChangeEmailWrongPassword,
  isTooManyEmailVerificationRequestsError,
} from '../../util/errors';
import {
  FieldPhoneNumberInput,
  Form,
  PrimaryButton,
  FieldTextInput,
  LocationAutocompleteInputField,
  FieldBirthdayInput,
  FieldSelect,
} from '../../components';

import css from './ContactDetailsForm.module.css';
import config from '../../config';

const SHOW_EMAIL_SENT_TIMEOUT = 2000;

const identity = v => v;

class ContactDetailsFormComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { showVerificationEmailSentMessage: false, showResetPasswordMessage: false };
    this.emailSentTimeoutId = null;
    this.handleResendVerificationEmail = this.handleResendVerificationEmail.bind(this);
    this.handleResetPassword = this.handleResetPassword.bind(this);
    this.submittedValues = {};
  }

  componentWillUnmount() {
    window.clearTimeout(this.emailSentTimeoutId);
  }

  handleResendVerificationEmail() {
    this.setState({ showVerificationEmailSentMessage: true });

    this.props.onResendVerificationEmail().then(() => {
      // show "verification email sent" text for a bit longer.
      this.emailSentTimeoutId = window.setTimeout(() => {
        this.setState({ showVerificationEmailSentMessage: false });
      }, SHOW_EMAIL_SENT_TIMEOUT);
    });
  }

  handleResetPassword() {
    this.setState({ showResetPasswordMessage: true });
    const email = this.props.currentUser.attributes.email;
    this.props.onResetPassword(email);
  }

  render() {
    return (
      <FinalForm
        {...this.props}
        render={fieldRenderProps => {
          const {
            rootClassName,
            className,
            saveEmailError,
            saveProfileError,
            currentUser,
            formId,
            handleSubmit,
            inProgress,
            intl,
            invalid,
            sendVerificationEmailError,
            sendVerificationEmailInProgress,
            resetPasswordInProgress,
            values,
          } = fieldRenderProps;
          const {
            email,
            phoneNumber,
            residentialAddress,
            building,
            driversLicenceExpiryDate,
            driversLicenceIssuedBy,
            driversLicenceNumber,
          } = values;

          const user = ensureCurrentUser(currentUser);

          if (!user.id) {
            return null;
          }

          const { email: currentEmail, emailVerified, pendingEmail, profile } = user.attributes;

          // email

          // has the email changed
          const emailChanged = currentEmail !== email;

          const emailLabel = intl.formatMessage({
            id: 'ContactDetailsForm.emailLabel',
          });

          const emailPlaceholder = currentEmail || '';

          const emailRequiredMessage = intl.formatMessage({
            id: 'ContactDetailsForm.emailRequired',
          });
          const emailRequired = validators.required(emailRequiredMessage);
          const emailInvalidMessage = intl.formatMessage({
            id: 'ContactDetailsForm.emailInvalid',
          });
          const emailValid = validators.emailFormatValid(emailInvalidMessage);

          const tooManyVerificationRequests = isTooManyEmailVerificationRequestsError(
            sendVerificationEmailError
          );

          const emailTouched = this.submittedValues.email !== values.email;
          const emailTakenErrorText = isChangeEmailTakenError(saveEmailError)
            ? intl.formatMessage({ id: 'ContactDetailsForm.emailTakenError' })
            : null;

          let resendEmailMessage = null;
          if (tooManyVerificationRequests) {
            resendEmailMessage = (
              <span className={css.tooMany}>
                <FormattedMessage id="ContactDetailsForm.tooManyVerificationRequests" />
              </span>
            );
          } else if (
            sendVerificationEmailInProgress ||
            this.state.showVerificationEmailSentMessage
          ) {
            resendEmailMessage = (
              <span className={css.emailSent}>
                <FormattedMessage id="ContactDetailsForm.emailSent" />
              </span>
            );
          } else {
            resendEmailMessage = (
              <span
                className={css.helperLink}
                onClick={this.handleResendVerificationEmail}
                role="button"
              >
                <FormattedMessage id="ContactDetailsForm.resendEmailVerificationText" />
              </span>
            );
          }

          // Email status info: unverified, verified and pending email (aka changed unverified email)
          let emailVerifiedInfo = null;

          if (emailVerified && !pendingEmail && !emailChanged) {
            // Current email is verified and there's no pending unverified email
            emailVerifiedInfo = (
              <span className={css.emailVerified}>
                <FormattedMessage id="ContactDetailsForm.emailVerified" />
              </span>
            );
          } else if (!emailVerified && !pendingEmail) {
            // Current email is unverified. This is the email given in sign up form

            emailVerifiedInfo = (
              <span className={css.emailUnverified}>
                <FormattedMessage
                  id="ContactDetailsForm.emailUnverified"
                  values={{ resendEmailMessage }}
                />
              </span>
            );
          } else if (pendingEmail) {
            // Current email has been tried to change, but the new address is not yet verified

            const pendingEmailStyled = <span className={css.emailStyle}>{pendingEmail}</span>;
            const pendingEmailCheckInbox = (
              <span className={css.checkInbox}>
                <FormattedMessage
                  id="ContactDetailsForm.pendingEmailCheckInbox"
                  values={{ pendingEmail: pendingEmailStyled }}
                />
              </span>
            );

            emailVerifiedInfo = (
              <span className={css.pendingEmailUnverified}>
                <FormattedMessage
                  id="ContactDetailsForm.pendingEmailUnverified"
                  values={{ pendingEmailCheckInbox, resendEmailMessage }}
                />
              </span>
            );
          }

          // phone
          const protectedData = profile.protectedData || {};
          const currentPhoneNumber = protectedData.phoneNumber;

          // has the phone number changed
          const phoneNumberChanged = currentPhoneNumber !== phoneNumber;

          const phonePlaceholder = intl.formatMessage({
            id: 'ContactDetailsForm.phonePlaceholder',
          });
          const phoneLabel = intl.formatMessage({ id: 'ContactDetailsForm.phoneLabel' });

          const phoneNumberRequiredMessage = intl.formatMessage({
            id: 'ContactDetailsForm.phoneNumberRequired',
          });
          const phoneNumberRequired = validators.required(phoneNumberRequiredMessage);

          const phoneNumberMinLengthMessage = intl.formatMessage({
            id: 'ContactDetailsForm.phoneNumberMinLength',
          });
          const phoneNumberMinLength = validators.minLength(phoneNumberMinLengthMessage, 10);

          const phoneNumberPrefixMessage = intl.formatMessage({
            id: 'ContactDetailsForm.phoneNumberPrefix',
          });
          const phoneNumberPrefix = validators.phoneNumberPrefix(phoneNumberPrefixMessage);

          // residentialAddress
          const currentResidentialAddress = protectedData.residentialAddress;

          // has the residential address changed
          const residentialAddressChanged =
            !!residentialAddress?.selectedPlace?.address &&
            currentResidentialAddress !== residentialAddress?.selectedPlace?.address;

          const residentialAddressLabel = intl.formatMessage({
            id: 'SignupForm.residentialAddressLabel',
          });
          const residentialAddressPlaceholder = intl.formatMessage({
            id: 'SignupForm.residentialAddressPlaceholder',
          });
          const residentialAddressRequiredMessage = intl.formatMessage({
            id: 'SignupForm.residentialAddressRequired',
          });
          const residentialAddressNotRecognizedMessage = intl.formatMessage({
            id: 'SignupForm.residentialAddressNotRecognized',
          });

          const types = ['address'];

          const currentBuilding = protectedData.building;
          const buildingChanged = currentBuilding !== building;

          const optionalText = intl.formatMessage({
            id: 'EditListingLocationForm.optionalText',
          });
          const buildingMessage = intl.formatMessage(
            { id: 'EditListingLocationForm.building' },
            { optionalText: optionalText }
          );
          const buildingPlaceholderMessage = intl.formatMessage({
            id: 'EditListingLocationForm.buildingPlaceholder',
          });

          // has the licence number changed
          const currentDriversLicenceNumber = protectedData.driversLicenceNumber;
          const driversLicenceNumberChanged = currentDriversLicenceNumber !== driversLicenceNumber;

          // driversLicenceNumber
          const driversLicenceNumberLabel = intl.formatMessage({
            id: 'SignupForm.driversLicenceNumberLabel',
          });
          const driversLicenceNumberPlaceholder = intl.formatMessage({
            id: 'SignupForm.driversLicenceNumberPlaceholder',
          });
          const driversLicenceNumberRequiredMessage = intl.formatMessage({
            id: 'SignupForm.driversLicenceNumberRequired',
          });
          const driversLicenceNumberRequired = validators.required(
            driversLicenceNumberRequiredMessage
          );
          const driversLicenceNumberMinLengthMessage = intl.formatMessage({
            id: 'SignupForm.driversLicenceNumberMinLength',
          });
          const driversLicenceNumberMinLength = validators.minLength(
            driversLicenceNumberMinLengthMessage,
            6
          );

          // has the licence expiry changed
          const currentDriversLicenceExpiryDate = protectedData.driversLicenceExpiryDate;
          const driversLicenceExpiryDateChanged =
            currentDriversLicenceExpiryDate !== driversLicenceExpiryDate;

          // driversLicenceExpiryDate
          const driversLicenceExpiryDateLabel = intl.formatMessage({
            id: 'SignupForm.driversLicenceExpiryDateLabel',
          });
          const driversLicenceExpiryDateRequiredMessage = intl.formatMessage({
            id: 'SignupForm.driversLicenceExpiryDateRequired',
          });
          const driversLicenceExpiryDateRequired = validators.required(
            driversLicenceExpiryDateRequiredMessage
          );
          const minExpiryRequired = validators.dateIsInFuture(
            'Expiry date cannot be in the past',
            0
          );

          // has the licence issued-by changed
          const currentDriversLicenceIssuedBy = protectedData.driversLicenceIssuedBy;
          const driversLicenceIssuedByChanged =
            currentDriversLicenceIssuedBy !== driversLicenceIssuedBy;

          // driversLicenceIssuedBy
          const driversLicenceIssuedByLabel = intl.formatMessage({
            id: 'SignupForm.driversLicenceIssuedByLabel',
          });
          const driversLicenceIssuedByPlaceholder = intl.formatMessage({
            id: 'SignupForm.driversLicenceIssuedByPlaceholder',
          });
          const driversLicenceIssuedByRequiredMessage = intl.formatMessage({
            id: 'SignupForm.driversLicenceIssuedByRequired',
          });
          const driversLicenceIssuedByRequired = validators.required(
            driversLicenceIssuedByRequiredMessage
          );
          const states = config.custom.states;

          // password
          const passwordLabel = intl.formatMessage({
            id: 'ContactDetailsForm.passwordLabel',
          });
          const passwordPlaceholder = intl.formatMessage({
            id: 'ContactDetailsForm.passwordPlaceholder',
          });
          const passwordRequiredMessage = intl.formatMessage({
            id: 'ContactDetailsForm.passwordRequired',
          });

          const passwordRequired = validators.requiredStringNoTrim(passwordRequiredMessage);

          const passwordMinLengthMessage = intl.formatMessage(
            {
              id: 'ContactDetailsForm.passwordTooShort',
            },
            {
              minLength: validators.PASSWORD_MIN_LENGTH,
            }
          );

          const passwordMinLength = validators.minLength(
            passwordMinLengthMessage,
            validators.PASSWORD_MIN_LENGTH
          );

          const passwordValidators = emailChanged
            ? validators.composeValidators(passwordRequired, passwordMinLength)
            : null;

          const passwordFailedMessage = intl.formatMessage({
            id: 'ContactDetailsForm.passwordFailed',
          });
          const passwordTouched = this.submittedValues.currentPassword !== values.currentPassword;
          const passwordErrorText = isChangeEmailWrongPassword(saveEmailError)
            ? passwordFailedMessage
            : null;

          const confirmClasses = classNames(css.confirmChangesSection, {
            [css.confirmChangesSectionVisible]: emailChanged,
          });

          // generic error
          const isGenericEmailError = saveEmailError && !(emailTakenErrorText || passwordErrorText);

          let genericError = null;

          if (isGenericEmailError && saveProfileError) {
            genericError = (
              <span className={css.error}>
                <FormattedMessage id="ContactDetailsForm.genericFailure" />
              </span>
            );
          } else if (isGenericEmailError) {
            genericError = (
              <span className={css.error}>
                <FormattedMessage id="ContactDetailsForm.genericEmailFailure" />
              </span>
            );
          } else if (saveProfileError) {
            genericError = (
              <span className={css.error}>
                <FormattedMessage id="ContactDetailsForm.genericProfileUpdateFailure" />
              </span>
            );
          }

          const sendPasswordLink = (
            <span className={css.helperLink} onClick={this.handleResetPassword} role="button">
              <FormattedMessage id="ContactDetailsForm.resetPasswordLinkText" />
            </span>
          );

          const resendPasswordLink = (
            <span className={css.helperLink} onClick={this.handleResetPassword} role="button">
              <FormattedMessage id="ContactDetailsForm.resendPasswordLinkText" />
            </span>
          );

          const resetPasswordLink =
            this.state.showResetPasswordMessage || resetPasswordInProgress ? (
              <>
                <FormattedMessage
                  id="ContactDetailsForm.resetPasswordLinkSent"
                  values={{
                    email: <span className={css.emailStyle}>{currentUser.attributes.email}</span>,
                  }}
                />{' '}
                {resendPasswordLink}
              </>
            ) : (
              sendPasswordLink
            );

          const classes = classNames(rootClassName || css.root, className);
          const submittedOnce = Object.keys(this.submittedValues).length > 0;
          const pristineSinceLastSubmit = submittedOnce && isEqual(values, this.submittedValues);
          const submitDisabled =
            invalid ||
            pristineSinceLastSubmit ||
            inProgress ||
            !(
              emailChanged ||
              phoneNumberChanged ||
              residentialAddressChanged ||
              buildingChanged ||
              driversLicenceIssuedByChanged ||
              driversLicenceNumberChanged ||
              driversLicenceExpiryDateChanged
            );

          return (
            <Form
              className={classes}
              onSubmit={e => {
                this.submittedValues = values;
                handleSubmit(e);
              }}
            >
              <div className={css.contactDetailsSection}>
                <FieldTextInput
                  type="email"
                  name="email"
                  id={formId ? `${formId}.email` : 'email'}
                  label={emailLabel}
                  placeholder={emailPlaceholder}
                  autoComplete="email"
                  validate={validators.composeValidators(emailRequired, emailValid)}
                  customErrorText={emailTouched ? null : emailTakenErrorText}
                />
                {emailVerifiedInfo}
                <FieldPhoneNumberInput
                  className={css.phone}
                  name="phoneNumber"
                  id={formId ? `${formId}.phoneNumber` : 'phoneNumber'}
                  label={phoneLabel}
                  placeholder={phonePlaceholder}
                  autoComplete="mobile"
                  validate={validators.composeValidators(
                    phoneNumberRequired,
                    phoneNumberMinLength,
                    phoneNumberPrefix
                  )}
                />
                {config.custom.featureToggles[config.env].updateResidentialAddress ? (
                  <>
                    <LocationAutocompleteInputField
                      className={css.locationAddress}
                      inputClassNames={[css.locationAutocompleteInput]}
                      iconClassName={css.locationAutocompleteInputIcon}
                      predictionsClassName={css.predictionsRoot}
                      validClassName={css.validLocation}
                      transparentBackground={true}
                      name="residentialAddress"
                      label={residentialAddressLabel}
                      placeholder={residentialAddressPlaceholder}
                      useDefaultPredictions={false}
                      format={identity}
                      valueFromForm={residentialAddress}
                      validate={validators.composeValidators(
                        validators.autocompleteSearchRequired(residentialAddressRequiredMessage),
                        validators.notEmptyAddress(residentialAddressNotRecognizedMessage)
                      )}
                      id={formId ? `${formId}.residentialAddress` : 'residentialAddress'}
                      types={types}
                    />
                    <FieldTextInput
                      className={css.building}
                      type="text"
                      name="building"
                      id="building"
                      label={buildingMessage}
                      placeholder={buildingPlaceholderMessage}
                    />
                    <FieldTextInput
                      className={css.driversLicenceNumber}
                      type="text"
                      id={formId ? `${formId}.driversLicenceNumber` : 'driversLicenceNumber'}
                      name="driversLicenceNumber"
                      label={driversLicenceNumberLabel}
                      placeholder={driversLicenceNumberPlaceholder}
                      validate={validators.composeValidators(
                        driversLicenceNumberRequired,
                        driversLicenceNumberMinLength
                      )}
                    />
                    <label
                      htmlFor={
                        formId ? `${formId}.driversLicenceExpiryDate` : 'driversLicenceExpiryDate'
                      }
                      className={css.driversLicenceLabel}
                    >
                      {driversLicenceExpiryDateLabel}
                    </label>
                    <FieldBirthdayInput
                      id={
                        formId ? `${formId}.driversLicenceExpiryDate` : 'driversLicenceExpiryDate'
                      }
                      name="driversLicenceExpiryDate"
                      className={css.driversLicenceExpiryDate}
                      label={'Day'}
                      labelForMonth={'Month'}
                      labelForYear={'Year'}
                      format={v => v}
                      valueFromForm={values.driversLicenceExpiryDate}
                      years={'lth'}
                      validate={validators.composeValidators(
                        driversLicenceExpiryDateRequired,
                        minExpiryRequired
                      )}
                    />
                    <FieldSelect
                      className={css.driversLicenceIssuedBy}
                      id={formId ? `${formId}.driversLicenceIssuedBy` : 'driversLicenceIssuedBy'}
                      name="driversLicenceIssuedBy"
                      label={driversLicenceIssuedByLabel}
                      validate={driversLicenceIssuedByRequired}
                    >
                      <option disabled value="">
                        {driversLicenceIssuedByPlaceholder}
                      </option>
                      {states.map(c => (
                        <option key={c.key} value={c.key}>
                          {c.label}
                        </option>
                      ))}
                    </FieldSelect>
                  </>
                ) : null}
              </div>

              <div className={confirmClasses}>
                <h3 className={css.confirmChangesTitle}>
                  <FormattedMessage id="ContactDetailsForm.confirmChangesTitle" />
                </h3>
                <p className={css.confirmChangesInfo}>
                  <FormattedMessage id="ContactDetailsForm.confirmChangesInfo" />
                  <br />
                  <FormattedMessage
                    id="ContactDetailsForm.resetPasswordInfo"
                    values={{ resetPasswordLink }}
                  />
                </p>

                <FieldTextInput
                  className={css.password}
                  type="password"
                  name="currentPassword"
                  id={formId ? `${formId}.currentPassword` : 'currentPassword'}
                  autoComplete="current-password"
                  label={passwordLabel}
                  placeholder={passwordPlaceholder}
                  validate={passwordValidators}
                  customErrorText={passwordTouched ? null : passwordErrorText}
                />
              </div>

              <div className={css.bottomWrapper}>
                {genericError}
                <PrimaryButton
                  type="submit"
                  inProgress={inProgress}
                  ready={pristineSinceLastSubmit}
                  disabled={submitDisabled}
                >
                  <FormattedMessage id="ContactDetailsForm.saveChanges" />
                </PrimaryButton>
              </div>
            </Form>
          );
        }}
      />
    );
  }
}

ContactDetailsFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  formId: null,
  saveEmailError: null,
  saveProfileError: null,
  inProgress: false,
  sendVerificationEmailError: null,
  sendVerificationEmailInProgress: false,
  email: null,
  phoneNumber: null,
  residentialAddress: null,
  resetPasswordInProgress: false,
  resetPasswordError: null,
};

const { bool, func, string } = PropTypes;

ContactDetailsFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  formId: string,
  saveEmailError: propTypes.error,
  saveProfileError: propTypes.error,
  inProgress: bool,
  intl: intlShape.isRequired,
  onResendVerificationEmail: func.isRequired,
  ready: bool.isRequired,
  sendVerificationEmailError: propTypes.error,
  sendVerificationEmailInProgress: bool,
  resetPasswordInProgress: bool,
  resetPasswordError: propTypes.error,
};

const ContactDetailsForm = compose(injectIntl)(ContactDetailsFormComponent);

ContactDetailsForm.displayName = 'ContactDetailsForm';

export default ContactDetailsForm;
